<template>
    <div class="bg-semidark">
        <div class="container py-5">
            <div class="row row cols-1 pt-lg-5 mb-lg-3">
                <div class="col">
                    <h2 class="home-section-title" v-html="title"></h2>
                </div>
            </div>

            <div class="row justify-content-end">
                <div id="news" class="news-slider-wrapper col-lg-9 px-5 active">
                    <div class="d-none d-lg-flex swiper-button-next custom-swiper-button-next"></div>
                    <div class="d-none d-lg-flex swiper-button-prev custom-swiper-button-prev"></div>

                    <div class="swiper homeslider-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in blogs" :key="item.id">
                                <a :href="item.link">
                                    <div class="card">
                                        <img :src="asset+'thumbnail/'+item.thumbnail" class="card-img-top" :alt="item.title">
                                        <div class="card-body p-3">
                                            <h5 class="card-title">{{item.title}}</h5>
                                            <p class="card-text">{{item.content.substr(0, 80)}}...</p>
                                            <div class="card-author">
                                                <span>By {{item.author}}</span>
                                                <span>{{dateFormat(item.date)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper, {Navigation} from "swiper"
    import moment from 'moment'

    export default {
        props: ['blogs', 'asset'],

        data() {
            return {
                title: this.$page.props.lang == 'id' ? '<strong>Artikel</strong> & Event' : '<strong>News</strong> & Event',
            }
        },

        computed: {
            dateFormat() {
                return date => moment(date).format('ddd, MMM Do YYYY')
            }
        },

        mounted () {
            Swiper.use([Navigation]);

            let swiper = new Swiper('.homeslider-swiper', {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                },
            });
        }
    }
</script>