<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <h2 class="headline mb-5">{{title_1}}</h2>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="container">
                    <div class="row mb-5 pb-5">
                        <img :src="asset+'images/'+machinery.value.image_1" class="img-eq d-none d-lg-block p-0" alt="Machinery and Equipment">
                        <div class="col-12 col-md-5 p-0">
                            <img :src="asset+'images/'+machinery.value.image_1" class="d-lg-none" alt="Machinery and Equipment">
                        </div>
                        <div class="col-12 col-md-7" v-html="content_1"></div>
                    </div>
                </div>
            </div>
            <div class="columns my-5 py-5">
                <div class="container">
                    
                    <div class="row">
                        <div class="col-12 col-6">
                            <h2 class="underline-eq mb-5">{{title_2}}</h2>
                        </div>
                        <div class="col-12 col-lg-6 py-5 t-green" v-html="content_2"></div>
                        <div class="col-12 col-lg-6">
                            <div class="row row-cols-lg-2 row-cols-1 g-3 justify-content-end">
                                <img :src="asset+'images/'+machinery.value.image_2" alt="Machinery and Equipment">
                                <img :src="asset+'images/'+machinery.value.image_3" alt="Machinery and Equipment">
                                <img :src="asset+'images/'+machinery.value.image_4" alt="Machinery and Equipment">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'machinery',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.machinery.value.page_title_id : this.machinery.value.page_title_en,
                background: this.asset+'images/'+this.machinery.value.page_title_background,
                title_1: this.$page.props.lang == 'id' ? this.machinery.value.title_1_id : this.machinery.value.title_1_en,
                title_2: this.$page.props.lang == 'id' ? this.machinery.value.title_2_id : this.machinery.value.title_2_en,
                content_1: this.$page.props.lang == 'id' ? this.machinery.value.content_1_id : this.machinery.value.content_1_en,
                content_2: this.$page.props.lang == 'id' ? this.machinery.value.content_2_id : this.machinery.value.content_2_en,
            }
        },
    }
</script>
