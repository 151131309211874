<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <HeroComponent :hero="hero" :asset="asset"/>

        <!-- Home About Us -->
        <AboutSection :about="about" :mission="mission" :vision="vision" :asset="asset" />

        <!-- Home Product -->
        <OurProductSection :home_product="home_product" :asset="asset" />

        <!-- Home Capacities -->
        <Map :maps="maps" :home_global_market="home_global_market" :asset="asset" />

        <!-- Home Facilities -->
        <OurFacilities :home_facilities="home_facilities" :asset="asset" />
        
        <!-- Home Sertifikasi -->
        <Certification :home_network="home_network" :home_certification="home_certification" :asset="asset" />
        
        <!-- Home Sumber Daya Manusia -->
        <OurPeople :home_people="home_people" :asset="asset" />
        
        <!-- Home News -->
        <News :blogs="blogs" :asset="asset" />

        <!-- Home Career -->
        <Inquiry :asset="asset" />

        <!-- Home Contact -->
        <Contact :home_contact="home_contact" :asset="asset" />
        
    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import HeroComponent from '@/Web/Components/Hero'
    import AboutSection from '@/Web/Components/AboutSection'
    import OurProductSection from '@/Web/Components/OurProductSection'
    import Map from '@/Web/Components/Map'
    import OurFacilities from '@/Web/Components/OurFacilities'
    import Certification from '@/Web/Components/Certification'
    import OurPeople from '@/Web/Components/OurPeople'
    import News from '@/Web/Components/News'
    import Inquiry from '@/Web/Components/MailInquiry'
    import Contact from '@/Web/Components/MailMessage'

    export default {
        components: {
            Layout,
            HeroComponent,
            AboutSection,
            OurProductSection,
            Map,
            OurFacilities,
            Certification,
            OurPeople,
            News,
            Inquiry,
            Contact
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'hero',
            'about',
            'mission',
            'vision',
            'home_product',
            'home_global_market',
            'maps',
            'home_facilities',
            'home_certification',
            'home_network',
            'home_people',
            'blogs',
            'home_contact',
            'asset'
        ],
    }
</script>