<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container py-5">
                <div class="row row-cols-1 main-content">
                    <div class="col-12 col-lg-7">
                        <div class="content headline">
                            <span v-html="title"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid p-0 bg-home-about content content-about">
                <div class="container py-5">
                    <div class="row row-cols-1 row-cols-lg-2 g-4 pb-lg-5">
                        <div class="col">
                            <img class="d-block d-sm-none mw-100 home-about-image-mobile" :src="asset+'images/'+about.value.image" alt="Hand">
                        </div>
                        <div class="col px-lg-3">
                            <span v-html="content"></span>
                        </div>
                    </div>
                </div>
            
                <!-- Image -->
                <img class="home-about-image" :src="asset+'images/'+about.value.image" alt="Hand">
            </div>
            <div class="container-fluid">
                <div class="row about-vismis text-center text-white">
                    <div class="col-12 col-md-6 bg-visi p-5">
                        <img :src="asset+'images/'+vision.value.icon" alt="">
                        <h4 class="text-uppercase t-white s-18 my-4">{{vision_title}}</h4>
                        <span v-html="vision_content"></span>
                    </div>
                    <div class="col-12 col-md-6 bg-misi p-5">
                        <img :src="asset+'images/'+mission.value.icon" alt="">
                        <h4 class="text-uppercase t-white s-18 my-4">{{mission_title}}</h4>
                        <span v-html="mission_content" class="d-flex flex-wrap justify-content-center text-start"></span>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'about',
            'vision',
            'mission',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.about.value.page_title_id : this.about.value.page_title_en,
                background: this.asset+'images/'+this.about.value.page_title_background,
                title: this.$page.props.lang == 'id' ? this.about.value.title_id : this.about.value.title_en,
                content: this.$page.props.lang == 'id' ? this.about.value.content_id : this.about.value.content_en,
                vision_title: this.$page.props.lang == 'id' ? this.vision.value.title_id : this.vision.value.title_en,
                vision_content: this.$page.props.lang == 'id' ? this.vision.value.content_id : this.vision.value.content_en,
                mission_title: this.$page.props.lang == 'id' ? this.mission.value.title_id : this.mission.value.title_en,
                mission_content: this.$page.props.lang == 'id' ? this.mission.value.content_id : this.mission.value.content_en,
            }
        }

        
    }
</script>