<template>
    <div id="header-wrapper">
        <!-- Top Bar -->
        <div class="top-bar bg-info">
            <div class="container">
                <div class="row jus">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-8 d-block d-lg-flex justify-content-between">
                        <div class="top-bar-contact">
                            <span>Email: <a :href="`mailto:${profile.value.email}`">{{profile.value.email}}</a></span>
                            <br class="d-lg-none d-block">
                            <span>Phone: <a :href="`tel:${profile.value.phone}`">{{profile.value.phone}}</a></span>
                        </div>
                        <div class="top-bar-social d-inline-flex">
                            <a :href="socials.value.twitter"><i class="fab fa-twitter fa-fw"></i></a>
                            <a :href="socials.value.facebook"><i class="fab fa-facebook fa-fw"></i></a>
                            <a :href="socials.value.instagram"><i class="fab fa-instagram fa-fw"></i></a>
                            <a :href="route('change-language', {lang: 'id'})"><img width="20" :src="`${asset}flag/indonesia.png`" alt="indonesia"></a>
                            <a :href="route('change-language', {lang: 'en'})"><img width="20" :src="`${asset}flag/united-kingdom.png`" alt="english"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
            <div class="container">
                <a class="navbar-brand" href="#">
                    <img height="60" :src="asset+'images/'+logo.value.logo_light" alt="Logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="headerMenu">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 px-lg-3">
                        <li class="nav-item">
                            <a class="nav-link" :class="route().current('home') ? 'active' : ''" :href="route('home')">{{home}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dropdown-toggle" :class="route().current('about') || route().current('milestones') ? 'active' : ''" :href="route('about')" id="navAbout" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{about}}</a>
                            <ul class="dropdown-menu" aria-labelledby="navAbout">
                                <li class="nav-item"><a :href="route('about')" class="nav-link">{{company_in_brief}}</a></li>
                                <li class="nav-item"><a :href="route('milestones')" class="nav-link">{{milestone}}</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dropdown-toggle" :class="route().current('product') || route().current('carrageenan') || route().current('product-application') ? 'active' : ''" :href="route('product')" id="navProduct" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{product}}</a>
                            <ul class="dropdown-menu" aria-labelledby="navProduct">
                                <li class="nav-item"><a :href="route('product')" class="nav-link">{{product_exellence}}</a></li>
                                <li class="nav-item"><a :href="route('carrageenan')" class="nav-link">{{carrageenan}}</a></li>
                                <li class="nav-item"><a :href="route('product-application')" class="nav-link">{{product_application}}</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dropdown-toggle" :class="route().current('local-product') || route().current('production-process') || route().current('machinery-and-equipment') || route().current('quality-control') || route().current('our-people') ? 'active' : ''" :href="route('local-product')" id="navCapacities" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{capacities}}</a>
                            <ul class="dropdown-menu" aria-labelledby="navCapacities">
                                <li class="nav-item"><a :href="route('local-product')" class="nav-link">{{local_product}}</a></li>
                                <li class="nav-item"><a :href="route('production-process')" class="nav-link">{{production_process}}</a></li>
                                <li class="nav-item"><a :href="route('our-people')" class="nav-link">{{our_people}}</a></li>
                                <li class="nav-item"><a :href="route('machinery-and-equipment')" class="nav-link">{{machinery}}</a></li>
                                <li class="nav-item"><a :href="route('quality-control')" class="nav-link">{{quality}}</a></li>
                                <li v-show="licenses" class="nav-item"><a :href="route('home')+'#certification_and_licenses'" class="nav-link">{{licenses}}</a></li>
                                <li v-show="partners" class="nav-item"><a :href="route('home')+'#networks_and_partners'" class="nav-link">{{partners}}</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="route().current('news-event') ? 'active' : ''" :href="route('news-event')">{{news}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="route().current('contact') ? 'active' : ''" :href="route('contact')">{{contact}}</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-2 ms-lg-5">
                        <li class="nav-item">
                            <a class="nav-link search" :href="route('search')"><i class="fas fa-search fa-fw"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Logo on Desktop -->
        <div class="logo-desktop-wrapper">
            <img :src="asset+'images/'+logo.value.logo_dark" alt="Logo Desktop">
        </div>
    </div>
</template>

<script>
    export default {
        props: ['menu', 'logo', 'profile', 'socials', 'asset'],
        data() {
            return {
                home: this.$page.props.lang == 'id' ? this.menu.value.home_id : this.menu.value.home_en,
                about: this.$page.props.lang == 'id' ? this.menu.value.about_id : this.menu.value.about_en,
                company_in_brief: this.$page.props.lang == 'id' ? this.menu.value.company_in_brief_id : this.menu.value.company_in_brief_en,
                milestone: this.$page.props.lang == 'id' ? this.menu.value.milestone_id : this.menu.value.milestone_en,
                product: this.$page.props.lang == 'id' ? this.menu.value.product_id : this.menu.value.product_en,
                product_exellence: this.$page.props.lang == 'id' ? this.menu.value.product_exellence_id : this.menu.value.product_exellence_en,
                carrageenan: this.$page.props.lang == 'id' ? this.menu.value.carrageenan_id : this.menu.value.carrageenan_en,
                product_application: this.$page.props.lang == 'id' ? this.menu.value.product_application_id : this.menu.value.product_application_en,
                capacities: this.$page.props.lang == 'id' ? this.menu.value.capacities_id : this.menu.value.capacities_en,
                local_product: this.$page.props.lang == 'id' ? this.menu.value.local_product_id : this.menu.value.local_product_en,
                production_process: this.$page.props.lang == 'id' ? this.menu.value.production_process_id : this.menu.value.production_process_en,
                our_people: this.$page.props.lang == 'id' ? this.menu.value.our_people_id : this.menu.value.our_people_en,
                machinery: this.$page.props.lang == 'id' ? this.menu.value.machinery_id : this.menu.value.machinery_en,
                quality: this.$page.props.lang == 'id' ? this.menu.value.quality_id : this.menu.value.quality_en,
                news: this.$page.props.lang == 'id' ? this.menu.value.news_id : this.menu.value.news_en,
                contact: this.$page.props.lang == 'id' ? this.menu.value.contact_id : this.menu.value.contact_en,
                // Additional
                licenses: this.menu.value.licenses_id ? this.$page.props.lang == 'id' ? this.menu.value.licenses_id : this.menu.value.licenses_en : '',
                partners: this.menu.value.partners_id ? this.$page.props.lang == 'id' ? this.menu.value.partners_id : this.menu.value.partners_en : '',
            }
        },
    }
</script>