<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row row-col-1 mb-5 pb-5">
                    <img :src="asset+'images/'+production_process.value.image" class="col-12" alt="Production Process">
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'production_process',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.production_process.value.page_title_id : this.production_process.value.page_title_en,
                background: this.asset+'images/'+this.production_process.value.page_title_background,
            }
        },
    }
</script>
