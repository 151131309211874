<template>
    <div class="bg-product">
        <div class="container py-5">
            <div class="row row cols-1 pt-5 mb-5">
                <div class="col">
                <h2 class="home-section-title"><strong>{{title}}</strong></h2>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-lg-1">
                <div class="col">
                    <form @submit.prevent="submit" class="home-contact-form p-5 bg-semidark">
                        <div class="row row-cols-1 row-cols-lg-2 g-4">
                            <div class="col">
                                <div class="mb-4">
                                    <input type="text" v-model="form.name" class="form-control" placeholder="Name">
                                    <validation :validation="form.errors.name" />
                                </div>
                                <div class="mb-4">
                                    <input type="email" v-model="form.email" class="form-control" placeholder="Email">
                                    <validation :validation="form.errors.email" />
                                </div>
                                <div class="mb-4">
                                    <input type="text" v-model="form.phone" class="form-control" placeholder="Phone">
                                    <validation :validation="form.errors.phone" />
                                </div>
                                <div class="">
                                    <input type="text" v-model="form.position" class="form-control" placeholder="Position">
                                    <validation :validation="form.errors.position" />
                                </div>
                            </div>
                            <div class="col">
                                <textarea rows="8" v-model="form.inquiry" class="form-control" placeholder="Inquiry"></textarea>
                                <validation :validation="form.errors.inquiry" />
                                <br>
                                <img v-if="captcha_image" :src="captcha_image" alt="Captcha" height="50">
                                <button type="button" v-on:click="reloadCaptcha" class="btn btn-primary ml-2 ms-2">Refresh Captcha</button>
                                <br>
                                <input type="hidden" v-model="form.captcha_key">
                                <input type="text" v-model="form.captcha" class="form-control">
                                <validation :validation="form.errors.captcha" />
                                <br>
                                <div v-show="is_success" class="alert alert-success mb-3 alert-dismissible fade show" role="alert">
                                    {{success_message}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <button type="submit" class="btn btn-primary col-12 mt-3">
                                    <button-label :processing="form.processing" :label="apply"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Validation from '@/Components/Validation'
    import ButtonLabel from '@/Web/Components/ButtonLabel'

    export default {
        components: {
            Validation,
            ButtonLabel
        },
        props: ['asset'],
        data() {
            return {
                title: this.$page.props.lang == 'id' ? 'Karir' : 'Career',
                apply: this.$page.props.lang == 'id' ? 'Lamar Sekarang' : 'Apply Now',
                success_message: this.$page.props.lang == 'id' ? 'Pesan Anda Berhasil Terkirim' : 'Your Message Sent Successfully',

                captcha_image: '',
                is_success: false,               

                form: this.$inertia.form({
                    name: '',
                    email: '',
                    phone: '',
                    position: '',
                    inquiry: '',
                    captcha: '',
                    captcha_key: '',
                }),
            }
        },

        methods: {
            reloadCaptcha() {
                window.axios.get(route('home')+'/captcha/api/mini')
                    .then(res => {
                        this.captcha_image = res.data.img;
                        this.form.captcha_key = res.data.key;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            submit() {
                let url = route('inquiry');
                this.form.post(url,{
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form.clearErrors();
                        this.is_success = true;
                    }
                })
            }
        },

        mounted() {
            this.reloadCaptcha();
        }
    }
</script>