<template>
    <footer>
        <div class="container py-5">
            <div class="row row-cols-1">
                <div class="col-12 footer-logo mb-4">
                <img height="80" :src="asset+'images/'+logo.value.logo_light" alt="Logo">
                </div>
            </div>
            <div class="row g-2">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="footer-description mb-4">
                                <p><strong>Head Office & Factory</strong></p>
                                <p class="ws-preline">{{profile.value.address_main}}</p>
                            </div>
                            <div class="col-md-4 footer-social d-flex justify-content-between mt-5 d-lg-flex d-none">
                                <a :href="socials.value.twitter"><i class="fab fa-twitter fa-fw fa-2x text-light me-3"></i></a>
                                <a :href="socials.value.facebook"><i class="fab fa-facebook fa-fw fa-2x text-light me-3"></i></a>
                                <a :href="socials.value.instagram"><i class="fab fa-instagram fa-fw fa-2x text-light"></i></a>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex flex-column">
                            <p><strong>Sales Representative Office</strong></p>
                            <p class="ws-preline">{{profile.value.address_sales}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex flex-wrap">
                    <div class="col-12 col-lg-6">
                        <img :src="asset+'images/'+profile.value.member_logo" class="col-11 mb-3" alt="">
                        <p class="p-3"><i>{{profile.value.member_desc}}</i></p>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row row-cols-2 footer-url">
                            <a :href="route('home')" class="col footer-link">{{home}}</a>
                            <a :href="route('about')" class="col footer-link">{{about}}</a>
                            <a :href="route('product')" class="col footer-link">{{product}}</a>
                            <a :href="route('local-product')" class="col footer-link">{{capacities}}</a>
                            <a :href="route('contact')" class="col footer-link">{{contact}}</a>
                        </div>
                        <div class="row row-cols-2 footer-url">
                            <!-- <a href="#" class="col footer-term s-16 font-light pb-3">{{term}}</a>
                            <a href="#" class="col footer-term s-16 font-light pb-3">{{site_map}}</a>
                            <a href="#" class="col footer-term s-16 font-light pb-3">{{privacy}}</a> -->
                        </div>
                    </div>
                </div>

                <div class="col-12 footer-social d-flex justify-content-around mt-5 d-lg-none d-flex">
                    <a :href="socials.value.twitter"><i class="fab fa-twitter fa-fw fa-2x text-light me-3"></i></a>
                    <a :href="socials.value.facebook"><i class="fab fa-facebook fa-fw fa-2x text-light me-3"></i></a>
                    <a :href="socials.value.instagram"><i class="fab fa-instagram fa-fw fa-2x text-light"></i></a>
                </div>
            </div>

            <!-- <div class="row row-cols-2">
                <div class="col">
                </div>
            </div> -->
        </div>

        <div class="separator"></div>

        <div class="container pb-4">
            <div class="row row-cols-1">
                <div class="col text-center text-light">
                    Copyright &copy; Bantimurung Indah {{getTheYear}}
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        props: ['menu', 'logo', 'profile', 'socials', 'asset'],
        data() {
            return {
                home: this.$page.props.lang == 'id' ? this.menu.value.home_id : this.menu.value.home_en,
                about: this.$page.props.lang == 'id' ? this.menu.value.about_id : this.menu.value.about_en,
                product: this.$page.props.lang == 'id' ? this.menu.value.product_id : this.menu.value.product_en,
                capacities: this.$page.props.lang == 'id' ? this.menu.value.capacities_id : this.menu.value.capacities_en,
                contact: this.$page.props.lang == 'id' ? this.menu.value.contact_id : this.menu.value.contact_en,

                term: this.$page.props.lang == 'id' ? 'Syarat & Ketentuan' : 'Term & Condition',
                site_map: this.$page.props.lang == 'id' ? 'Sitemap' : 'Sitemap',
                privacy: this.$page.props.lang == 'id' ? 'Kebijakan Privasi' : 'Privacy Policy',
            }
        },
        computed: {
            getTheYear: () => {
                const d = new Date();
                return d.getFullYear(); 
            }
        }
    }
</script>