<template>
    <div class="bg-product">
            <div class="container py-5">
                <div class="row row cols-1 pt-5 mb-5">
                    <div class="col">
                    <h2 class="home-section-title" v-html="title"></h2>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-lg-2 g-4 mb-5">
                    <div class="col">
                        <div class="home-contact-image">
                            <img :src="asset+'images/'+home_contact.value.image" alt="Contact Image">
                            <div class="home-contact-phone">
                                <p>{{home_contact.value.phone1}}</p>
                                <p>{{home_contact.value.phone2}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <form @submit.prevent="submit" class="home-contact-form p-4">
                            <div class="home-contact-form-title">
                                <h5>{{get_in}}</h5>
                            </div>
                            <div class="row row-cols-1 row-cols-lg-2 g-4 mb-4">
                                <div class="col">
                                    <input type="text" v-model="form.name" class="form-control" placeholder="Name">
                                    <validation :validation="form.errors.name" />
                                </div>
                                <div class="col">
                                    <input type="email" v-model="form.email" class="form-control" placeholder="Email">
                                    <validation :validation="form.errors.email" />
                                </div>
                                <div class="col">
                                    <input type="text" v-model="form.phone" class="form-control" placeholder="Phone">
                                    <validation :validation="form.errors.phone" />
                                </div>
                                <div class="col">
                                    <input type="text" v-model="form.subject" class="form-control" placeholder="Subject">
                                    <validation :validation="form.errors.subject" />
                                </div>
                            </div>
                            <div class="row row-cols-1 mb-3">
                                <div class="col mb-3">
                                    <textarea rows="5" v-model="form.message" class="form-control" placeholder="Message"></textarea>
                                    <validation :validation="form.errors.message" />
                                </div>
                                <div class="col">
                                    <img v-if="captcha_image" :src="captcha_image" alt="Captcha" height="50">
                                    <button type="button" v-on:click="reloadCaptcha" class="btn btn-primary ml-2 ms-2">Refresh Captcha</button>
                                    <br>
                                    <input type="hidden" v-model="form.captcha_key">
                                    <input type="text" v-model="form.captcha" class="form-control">
                                    <validation :validation="form.errors.captcha" />
                                </div>
                            </div>
                            <div class="row row-cols-1">
                                <div class="col">
                                    <div v-show="is_success" class="alert alert-success mb-3 alert-dismissible fade show" role="alert">
                                        {{success_message}}
                                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                    <button type="submit" class="btn btn-primary">
                                        <button-label :processing="form.processing" :label="apply"/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row row-cols-1">
                    <div class="col d-flex justify-content-end">
                        <img width="80" v-on:click="scrollToTop" class="btn-top-up" src="/images/btn-top-up.png" alt="">
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import Validation from '@/Components/Validation'
    import ButtonLabel from '@/Web/Components/ButtonLabel'

    export default {
        components: {
            Validation,
            ButtonLabel
        },
        props: ['home_contact','asset'],
        data() {
            return {
                title: this.$page.props.lang == 'id' ? '<strong>Kontak</strong> Kami' : '<strong>Contact</strong> Us',
                apply: this.$page.props.lang == 'id' ? 'Kirim' : 'Send',
                get_in: this.$page.props.lang == 'id' ? 'Hubungi Kami' : 'Get In Touch',
                success_message: this.$page.props.lang == 'id' ? 'Pesan Anda Berhasil Terkirim' : 'Your Message Sent Successfully',

                captcha_image: '',
                is_success: false,             

                form: this.$inertia.form({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    captcha: '',
                    captcha_key: '',
                }),
            }
        },

        methods: {
            scrollToTop () {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            reloadCaptcha() {
                window.axios.get(route('home')+'/captcha/api/mini')
                    .then(res => {
                        this.captcha_image = res.data.img;
                        this.form.captcha_key = res.data.key;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            submit() {
                let url = route('message');
                this.form.post(url,{
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form.clearErrors();
                        this.is_success = true;
                    }
                })
            }
        },

        mounted() {
            this.reloadCaptcha();
        }
    }
</script>