<template>
    <div>
        <div class="bg-semidark pb-0 bg-right-sprite">
            <div class="container py-5">
                <div class="row row cols-1 pt-5 mb-5">
                    <div class="col">
                        <h2 class="home-section-title" v-html="title"></h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-primary sdm">
            <div class="container py-4">
                <div class="row">
                    <div class="col-lg-4 offset-lg-1 py-2 pb-5">
                    <p class="mb-4">{{desc}}</p>
                    <a :href="route('our-people')" class="btn btn-sm btn-light">{{read}}</a>
                    </div>
                </div>
            </div>

            <img class="d-none d-sm-block sdm-image" :src="asset+'images/'+home_people.value.image" alt="SDM">
        </div>
    </div>
</template>

<script>
    export default {
        props: ['home_people', 'asset'],
        data() {
            return {
                title: this.$page.props.lang == 'id' ? '<strong>Sumber</strong> Daya Manusia' : '<strong>Our</strong> People',
                read: this.$page.props.lang == 'id' ? 'Selengkapnya' : 'Read More',
                desc: this.$page.props.lang == 'id' ? this.home_people.value.description_id : this.home_people.value.description_en,
            }
        }
    }
</script>