<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container-fluid p-0">
                <section class="timeline">
                    <div class="mentionScroll">{{scroll}}</div>
                    <div class="container">

                        <template v-for="(item, index) in milestones" :key="index">
                            <div v-if="index % 2 == 0" class="timeline-item">
                                <div class="timeline-img"></div>
                                <div class="timeline-content js--fadeInLeft">
                                    <img :src="asset+'images/'+item.icon" alt="Milestone Icon">
                                    <div class="boxDateDesc">
                                        <div class="date">{{item.year}}</div>
                                        <p>{{item.title}}</p>
                                    </div>
                                </div>
                            </div>
                    
                            <div v-else class="timeline-item">
                                <div class="timeline-img"></div>
                                <div class="timeline-content timeline-card js--fadeInRight">
                                    <img :src="asset+'images/'+item.icon" alt="Milestone Icon">
                                    <div class="boxDateDesc">
                                        <div class="date">{{item.year}}</div>
                                        <p>{{item.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>
                </section>
            </div>

        </div>
    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'milestone_page',
            'milestones',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.milestone_page.value.page_title_id : this.milestone_page.value.page_title_en,
                background: this.asset+'images/'+this.milestone_page.value.page_title_background,
                scroll: this.$page.props.lang == 'id' ? 'Eksplor kebawah' : 'Scroll to explore',
            }
        }

        
    }
</script>