<template>
    <nav class="d-flex justify-content-end my-5">
        <ul class="pagination">
            <li v-for="(link, index) in links" :key="index" class="page-item" :class="link.active ? 'active' : ''">
                <a class="page-link" :href="link.url" v-if="link.url"><span v-html="link.label"></span></a>
                <a class="page-link" href="#" v-else @click.prevent="handleNoLink"><span v-html="link.label"></span></a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            links: Array,
        },

        computed: {
            showPagination() {
                return this.links.length > 3
            }
        },

        methods: {
            handleNoLink() {
                return false
            }
        }
    }
</script>