<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row pageNews row-cols-1 row-cols-lg-3 g-3">
                    <div class="col" v-for="item in blogs.data" :key="item.id">
                        <div class="card h-100">
                            <div class="image-card"> 
                                <img :src="asset+'images/'+item.thumbnail" class="card-img-top" :alt="item.title">
                                <div class="card-author">
                                    <span>By {{item.author}}</span>
                                    <span>{{dateFormat(item.date)}}</span>
                                </div>
                            </div>
                            <div class="card-body p-3">
                                <h5 class="card-title s-21">{{item.title}}</h5>
                                <p class="card-text s-12">{{item.content}}</p>
                                <div class="card-link font-bold s-21">
                                    <a :href="item.link">{{read}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination :links="blogs.links" />
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'
    import Pagination from '@/Web/Components/Pagination'
    import moment from 'moment'

    export default {
        components: {
            Layout,
            PageTitle,
            Pagination
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'blog',
            'blogs',
            'asset'
        ],

        data () {
            return {
                read: this.$page.props.lang == 'id' ? 'Selengkapnya' : 'Read More' ,
                pageTitle: this.$page.props.lang == 'id' ? this.blog.value.page_title_id : this.blog.value.page_title_en,
                background: this.asset+'images/'+this.blog.value.page_title_background,
            }
        },

        computed: {
            dateFormat() {
                return date => moment(date).format('ddd, MMM Do YYYY')
            }
        },
    }
</script>
