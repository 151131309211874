<template>
    <div class="bg-home-about">
        <div class="container py-5">
            <div class="row row cols-1 pt-5">
                <div class="col">
                    <h2 class="home-about-title" v-html="title_about"></h2>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-lg-2 g-4 pb-lg-5">
                <div class="col">
                    <img class="d-block d-sm-none mw-100 home-about-image-mobile" :src="asset+'images/'+about.value.image" alt="Hand">
                </div>
                <div class="col px-lg-3">
                    <h3 class="home-about-subtitle mb-3">{{pageTitle}}</h3>
                    <p class="home-about-description mb-5 s-16" v-html="stripTitle"></p>
                    <a :href="route('about')" class="btn btn-sm btn-primary mb-5">{{read_more}}</a>
                    <div class="visi mb-5">
                        <div class="visi-title">
                            <img :src="asset+'/icon/icon-visi.png'" alt="Visi">
                            <h5>{{vision_title}}</h5>
                        </div>
                        <div class="visi-description">
                            <span class="s-16" v-html="vision_content"></span>
                        </div>
                    </div>
                    <div class="visi">
                        <div class="visi-title">
                            <img :src="asset+'/icon/icon-misi.png'" alt="Misi">
                            <h5>{{mission_title}}</h5>
                        </div>
                        <div class="visi-description">
                            <span class="s-16" v-html="mission_content"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Image -->
        <img class="home-about-image" :src="asset+'images/'+about.value.image" alt="Hand">
    </div>
</template>

<script>
    export default {
        props: ['about', 'vision', 'mission', 'asset'],
        data() {
            return {
                title_about: this.$page.props.lang == 'id' ? '<strong>Tentang</strong> Kami' : '<strong>About</strong> Us',
                pageTitle: this.$page.props.lang == 'id' ? this.about.value.page_title_id : this.about.value.page_title_en,
                title: this.$page.props.lang == 'id' ? this.about.value.title_id : this.about.value.title_en,
                vision_title: this.$page.props.lang == 'id' ? this.vision.value.title_id : this.vision.value.title_en,
                vision_content: this.$page.props.lang == 'id' ? this.vision.value.content_id : this.vision.value.content_en,
                mission_title: this.$page.props.lang == 'id' ? this.mission.value.title_id : this.mission.value.title_en,
                mission_content: this.$page.props.lang == 'id' ? this.mission.value.content_id : this.mission.value.content_en,
                read_more: this.$page.props.lang == 'id' ? 'Selengkapnya' : 'Read More' 
            }
        },
        computed: {
            stripTitle () {
                return this.title.replace(new RegExp('<[^>]*>', 'g'), '')
            }
        }
    }
</script>