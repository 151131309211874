<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container-fluid bg-product-page my-5">
                <div class="container">
                    <div class="row row-cols-1 row-cols-lg-2 g-4 pb-lg-5">
                        <div class="col">
                            <h3 class="s-52 font-semibold t-dgreen">{{title}}</h3>
                        </div>
                        <div class="col px-lg-3">
                            <div class="bg-green page-content p-4" v-html="content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row my-5 justify-around row-cols-lg-3 row-cols-1">
                    <template v-for="item, index in product.value.exellences" :key="index" >
                        <div class="produc-qua">
                            <span>{{$page.props.lang == 'id' ? item.text_id : item.text_en}}</span>
                            <img :src="asset+'images/'+item.icon" width="150" alt="Icon">
                        </div>
                    </template>
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'product',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.product.value.page_title_id : this.product.value.page_title_en,
                background: this.asset+'images/'+this.product.value.page_title_background,
                title: this.$page.props.lang == 'id' ? this.product.value.title_id : this.product.value.title_en,
                content: this.$page.props.lang == 'id' ? this.product.value.content_id : this.product.value.content_en,
            }
        },
    }
</script>