<template>
    <div>
        <header-component :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset" />

        <slot/>

        <footer-component :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset" />
    </div>
</template>

<script>
    import HeaderComponent from '@/Web/Components/Header'
    import FooterComponent from '@/Web/Components/Footer'

    export default {
        components: {
            HeaderComponent,
            FooterComponent
        },

        props: [
            'menu',
            'logo',
            'socials',
            'profile',
            'asset'
        ],
    }
</script>