<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container-fluid my-5">
                <div class="container">
                    <div class="row row-cols-1 row-cols-lg-2 g-4 pb-lg-5 align-items-center justify-content-center">
                        <div class="col">
                            <img :src="asset+'images/'+carrageenan.value.carrageenan_image" alt="Carrageenan Image">
                            <p class="t-green font-semibold text-center"><strong>{{caption}}</strong></p>
                        </div>
                        <div class="col px-lg-3">
                            <div class="bg-green page-content p-4" v-html="content"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="line"></div>
                <div class="container">
                    <div class="underline-title d-flex justify-content-between mb-4">
                        <h3 class="t-green s-24 font-bold">{{spec}}</h3>
                        <button class="t-green btn-transparent s-20" data-bs-toggle="collapse" href="#specCollapse" role="button" aria-expanded="true" aria-controls="specCollapse">
                            <i class="fas fa-arrow-circle-down"></i>
                        </button>
                    </div>
                    <div class="row py-4 proses-box collapse show" id="specCollapse">
                        <div class="col-12 col-lg-3">
                            <ul id='carousel-custom-dots' class='list-spek'>
                                <li v-for="item, index in product_spec" :key="index" v-on:click="gotoSlideSpec(index)" :class="{active: isSpecActice == index}">
                                    <span class="button secondary url">{{item.title}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-9">
                            <div class="col-12 col-lg-8 mr-at ml-at image-spek">
                                <div class="swiper spec-swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="item, index in product_spec" :key="index">                                            
                                            <div class="title-slide mb-4 s-16 t-green t-mons font-semibold">
                                                <img width="30" :src="asset+'icon/icon-produk.png'" alt="Icon"> {{item.title}}
                                            </div>
                                            <img :src="asset+'images/'+item.image" :alt="item.title">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="underline-title d-flex justify-content-between mb-4">
                        <h3 class="t-green s-24 font-bold">{{proc}}</h3>
                        <button class="t-green btn-transparent s-20" data-bs-toggle="collapse" href="#processCollapse" role="button" aria-expanded="true" aria-controls="processCollapse">
                            <i class="fas fa-arrow-circle-down"></i>
                        </button>
                    </div>
                    <div class="row py-4 proses-box collapse show" id="processCollapse">
                        <div class="col-12 col-lg-3">
                            <ul class='list-spek nav-spek'>
                                <li v-for="item, index in product_process" :key="index" v-on:click="gotoSlideProcess(index)" :class="{active: isProcessActice == index}">
                                    <span class="button secondary url">{{item.title}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-9">
                            <div class="col-12 col-lg-8 mr-at ml-at image-spek spec">
                                <div class="swiper process-swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="item, index in product_process" :key="index">
                                            <img :src="asset+'images/'+item.image" :alt="item.title">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'
    import Swiper, {Navigation} from "swiper";

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'carrageenan',
            'product_spec',
            'product_process',
            'asset'
        ],

        data () {
            return {
                swiperProcess: null,
                isProcessActice: 0,
                swiperSpec: null,
                isSpecActice: 0,
                pageTitle: this.$page.props.lang == 'id' ? this.carrageenan.value.page_title_id : this.carrageenan.value.page_title_en,
                background: this.asset+'images/'+this.carrageenan.value.page_title_background,
                caption: this.$page.props.lang == 'id' ? this.carrageenan.value.caption_id : this.carrageenan.value.caption_en,
                content: this.$page.props.lang == 'id' ? this.carrageenan.value.content_id : this.carrageenan.value.content_en,
                spec: this.$page.props.lang == 'id' ? 'Spesifikasi Produk' : 'Product Specification',
                proc: this.$page.props.lang == 'id' ? 'Proses Produksi' : 'Production Process',
            }
        },

        methods: {
            gotoSlideProcess(index) {
                this.swiperProcess.slideTo(index, 1000, false);
                this.isProcessActice = index;
            },
            gotoSlideSpec(index) {
                this.swiperSpec.slideTo(index, 1000, false);
                this.isSpecActice = index;
            }
        },

        mounted() {
            Swiper.use([Navigation])

            this.swiperSpec = new Swiper('.spec-swiper', {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
                // navigation: {
                //     nextEl: ".swiper-button-next",
                //     prevEl: ".swiper-button-prev",
                // }
            });

            this.swiperProcess = new Swiper('.process-swiper', {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
            });
        }
    }
</script>