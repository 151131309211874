<template>
    <div class="banner-page container-fluid p-0">
        <img :src="background" alt="">
        <div class="caption-page">
            <div class="container">
                <h2 class="title-page">{{title}}</h2>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'background'],
    }
</script>