<template>
    <div class="bg-semidark left-sprite">
        <div class="container py-5">
            <div id="certification_and_licenses" class="row row cols-1 pt-5 mb-5">
                <div class="col">
                <h2 class="home-section-title"><strong>{{title1}}</strong></h2>
                </div>
            </div>
            <div class="row justify-content-center row-cols-5 align-items-center text-center">
                <div v-for="item, index in home_certification.value.images" :key="index">
                    <img width="170" :src="asset+'images/'+item.image" alt="Sertifikasi">
                </div>
            </div>
        </div>
        <div class="container py-5">
            <div id="networks_and_partners" class="row row-cols-1 pt-5 mb-5">
                <div class="col">
                <h2 class="home-section-title"><strong>{{title2}}</strong></h2>
                </div>
            </div>
            <div class="row justify-content-center row-cols-4 align-items-center text-center g-4">
                <div v-for="item, index in home_network.value.images" :key="index">
                    <img width="170" :src="asset+'images/'+item.image" alt="Sertifikasi">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['home_certification', 'home_network', 'asset'],
        data() {
            return {
                title1: this.$page.props.lang == 'id' ? 'Sertifikasi & Lisensi' : 'Certifications & Licenses',
                title2: this.$page.props.lang == 'id' ? 'Jaringan & Partner' : 'Networks & Partners',
            }
        }
    }
</script>