<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row row-col-1">
                    <div class="col">
                        <h2 class="headline mb-5">{{title}}</h2>
                        <p><strong>{{desc}}</strong></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 capacities-map">				
                        <div id="global_market_map"></div>
                    </div>
                </div>
                <div class="row my-5">
                    <p><strong>{{desc2}}</strong></p>
                    <div class="col-12">
                        <ul class="d-flex list-country">
                            <li v-for="item, index in local_product.value.countries" :key="index">{{item.name}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'
    import Konva from 'konva';

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'local_product',
            'maps',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.local_product.value.page_title_id : this.local_product.value.page_title_en,
                background: this.asset+'images/'+this.local_product.value.page_title_background,
                title: this.$page.props.lang == 'id' ? this.local_product.value.title_id : this.local_product.value.title_en,
                desc: this.$page.props.lang == 'id' ? this.local_product.value.description_id : this.local_product.value.description_en,
                desc2: this.$page.props.lang == 'id' ? this.local_product.value.description_2_id : this.local_product.value.description_2_en,

                width: 750,
                height: 450,
                bg_width: 750,
                bg_height: 450,
                is_mobile: false,

                bg_map: this.asset+'konva/map.png',
                pin: this.asset+'konva/pin.png',

                stage: null,
                layer: null,
                group: null,
                pin_group: null,
                label_group: null,
            }
        },

        methods: {
            registerStage() {
                this.stage = new Konva.Stage({
                    container: '#global_market_map',
                    width: this.width,
                    height: this.height,
                    draggable: this.is_mobile,
                });

                if (this.is_mobile) {
                    this.stage.on("dragmove", function (evt) {
                        // const oldAbs = text_layer.absolutePosition();
                        // text_layer_abs = oldAbs;
                        // console.log(text_layer_abs);
                    });
                }
            },

            registerLayer() {
                this.layer = new Konva.Layer();

                this.group = new Konva.Group({
                    x: this.width / 2,
                    y: this.height / 2,
                    width: this.bg_width,
                    height: this.bg_height,
                    scaleX: 1.5,
                    scaleY: 1.5,
                    offset: {
                        x: 375,
                        y: 225
                    }
                });

                this.pin_group = new Konva.Group({
                    x: this.width / 2,
                    y: this.height / 2,
                    width: this.bg_width,
                    height: this.bg_height,
                    scaleX: 1.5,
                    scaleY: 1.5,
                    offset: {
                        x: 375,
                        y: 225
                    }
                });

                this.label_group = new Konva.Group({
                    x: this.width / 2,
                    y: this.height / 2,
                    width: this.bg_width,
                    height: this.bg_height,
                    scaleX: 1.5,
                    scaleY: 1.5,
                    offset: {
                        x: 375,
                        y: 225
                    }
                });
            },

            makeBackground() {
                let backgroundObj = new Image();

                backgroundObj.src = this.bg_map;
                backgroundObj.onload = () => {
                    let background = new Konva.Image({
                        image: backgroundObj,
                        x: 0,
                        y: 0,
                        width: this.bg_width,
                        height: this.bg_height,
                    });

                    this.group.add(background);
                }
            },

            buildMap() {
                this.maps.map(item => {
                    this.newPin(item);
                })
            },

            newPin(item) {
                let pinObj = new Image();

                pinObj.src = this.pin;
                pinObj.onload = () => {
                    let pin = new Konva.Image({
                        image: pinObj,
                        x: parseInt(item.x),
                        y: parseInt(item.y),
                        width: 30,
                        height: 30,
                        offset: {
                            x: 30 / 2,
                            y: 30 / 2
                        },
                        opacity: 0.9,
                        shadowColor: 'black',
                        shadowBlur: 3,
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowOpacity: 0.1,
                    });

                    var tooltip = new Konva.Label({
                        x: parseInt(item.x),
                        y: parseInt(item.y) - (30/2),
                        opacity: 0,
                        scaleY: 0,
                    });

                    tooltip.add(
                        new Konva.Tag({
                            fill: '#94d7f2',
                            pointerDirection: 'down',
                            pointerWidth: 10,
                            pointerHeight: 10,
                            lineJoin: 'round',
                            shadowColor: 'black',
                            shadowBlur: 5,
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            shadowOpacity: 0.5,
                        })
                    );

                    tooltip.add(new Konva.Text({
                        text: item.title,
                        fontSize: 20,
                        fontStyle: 'bold',
                        lineHeight: 1,
                        padding: 10,
                        fill: 'white'
                    }));

                    this.pin_group.add(pin);
                    this.label_group.add(tooltip);

                    pin.on('mouseover', (evt) => { this.mouseEnter(evt, tooltip) });
                    pin.on('mouseout',  (evt) => { this.mouseLeave(evt, tooltip) });
                    pin.on('touchstart', (evt) => { this.mouseEnter(evt, tooltip) });
                    pin.on('touchend',  (evt) => { this.mouseLeave(evt, tooltip) });
                }
            },

            mouseEnter(evt, tooltip) {
                var shape = evt.target;
                document.body.style.cursor = 'default';

                var tween = new Konva.Tween({
                    node: shape,
                    duration: 0.1,
                    scaleX: 1.1,
                    scaleY: 1.1,
                    opacity: 1,
                });

                var tween2 = new Konva.Tween({
                    node: tooltip,
                    duration: 0.1,
                    scaleY: 1,
                    opacity: 1,
                });

                tween.play();
                tween2.play();
            },

            mouseLeave(evt, tooltip) {
                var shape = evt.target;
                document.body.style.cursor = 'default';

                var tween = new Konva.Tween({
                    node: shape,
                    duration: 0.1,
                    scaleX: 1,
                    scaleY: 1,
                    opacity: 0.9
                });

                var tween2 = new Konva.Tween({
                    node: tooltip,
                    duration: 0.1,
                    scaleY: 0,
                    opacity: 0,
                });

                tween.play();
                tween2.play();
            }
        },

        mounted() {
            this.width = document.querySelector('#global_market_map').offsetWidth;
            this.height = document.querySelector('#global_market_map').offsetHeight;

            if (this.width < 700) {
                this.is_mobile = true;
            } else {
                this.is_mobile = false;
            }

            this.registerStage();
            this.registerLayer();

            this.makeBackground();

            this.buildMap();

            this.layer.add(this.group).add(this.pin_group).add(this.label_group);
            this.stage.add(this.layer);
        }
    }
</script>

<style scoped>
    #global_market_map {
        width: 100%;
        height: 800px;
    }
</style>