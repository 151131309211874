<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row row-cols-1 row-cols-lg-2 g-lg-5 g-3">
                    <div class="col" v-for="item in categories" :key="item.id">
                        <h3 class="s-16 t-green">{{item.title}}</h3>
                        <div class="col-12">
                            <div class="row row-cols-3 g-3 mb-4">
                                <img v-for="gallery in item.galleries" :key="gallery.id" :src="asset+'images/'+gallery.image" :alt="item.title">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'product_app',
            'categories',
            'asset'
        ],

        data () {
            return {
                pageTitle: this.$page.props.lang == 'id' ? this.product_app.value.page_title_id : this.product_app.value.page_title_en,
                background: this.asset+'images/'+this.product_app.value.page_title_background,
            }
        },
    }
</script>