<template>
    <div class="bg-semidark right-sprite">
        <div class="container py-5">
            <div class="row row cols-1 pt-5 mb-5">
                <div class="col">
                    <h2 class="home-section-title" v-html="our_facilities"></h2>
                    <div class="col-lg-5">
                        <p class="home-section-desc">{{desc}}</p>
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-lg-3 g-4">

                <div class="col facilities">
                    <a :href="route('production-process')">
                        <img class="facilities-image" :src="asset+'images/'+home_facilities.value.production_image" alt="Facilities">
                        <h5 class="facilities-title">{{production_title}}</h5>
                        <p class="facilities-desc">{{production_decs}}</p>
                    </a>
                </div>

                <div class="col facilities">
                    <a :href="route('machinery-and-equipment')">
                        <img class="facilities-image" :src="asset+'images/'+home_facilities.value.machinery_image" alt="Facilities">
                        <h5 class="facilities-title">{{machinery_title}}</h5>
                        <p class="facilities-desc">{{machinery_desc}}</p>
                    </a>
                </div>

                <div class="col facilities">
                    <a :href="route('quality-control')">
                        <img class="facilities-image" :src="asset+'images/'+home_facilities.value.quality_image" alt="Facilities">
                        <h5 class="facilities-title">{{quality_title}}</h5>
                        <p class="facilities-desc">{{quality_desc}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['home_facilities', 'asset'],
        data() {
            return {
                our_facilities: this.$page.props.lang == 'id' ? '<strong>Fasilitas</strong> Kami' : '<strong>Our</strong> Facilities',
                desc: this.$page.props.lang == 'id' ? this.home_facilities.value.description_id : this.home_facilities.value.description_en,
                production_title: this.$page.props.lang == 'id' ? this.home_facilities.value.production_title_id : this.home_facilities.value.production_title_en,
                production_decs: this.$page.props.lang == 'id' ? this.home_facilities.value.production_decs_id : this.home_facilities.value.production_decs_en,
                machinery_title: this.$page.props.lang == 'id' ? this.home_facilities.value.machinery_title_id : this.home_facilities.value.machinery_title_en,
                machinery_desc: this.$page.props.lang == 'id' ? this.home_facilities.value.machinery_desc_id : this.home_facilities.value.machinery_desc_en,
                quality_title: this.$page.props.lang == 'id' ? this.home_facilities.value.quality_title_id : this.home_facilities.value.quality_title_en,
                quality_desc: this.$page.props.lang == 'id' ? this.home_facilities.value.quality_desc_id : this.home_facilities.value.quality_desc_en,
            }
        }
    }
</script>