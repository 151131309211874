<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row row-col-1">
                    <div class="col">
                        <form @submit.prevent="submit" class="p-5">
                            <div class="row home-contact-form-title py-5 pt-0">
                                <h5 class="s-21 t-green">{{get_in_touch}}</h5>
                            </div>
                            <div class="row flex-row-reverse flex-lg-row g-4 page-contact">
                                <div class="col-12 col-md-7 px-lg-5">
                                    <div class="mb-4">
                                        <input type="text" v-model="form.name" class="form-control" placeholder="Name">
                                        <validation :validation="form.errors.name" />
                                    </div>
                                    <div class="mb-4">
                                        <input type="email" v-model="form.email" class="form-control" placeholder="Email">
                                        <validation :validation="form.errors.email" />
                                    </div>
                                    <div class="mb-4">
                                        <input type="text" v-model="form.phone" class="form-control" placeholder="Phone">
                                        <validation :validation="form.errors.phone" />
                                    </div>
                                    <div class="mb-4">
                                        <input type="text" v-model="form.subject" class="form-control" placeholder="Subject">
                                        <validation :validation="form.errors.subject" />
                                    </div>
                                    <div class="mb-4">
                                        <textarea rows="5" v-model="form.message" class="form-control" placeholder="Message"></textarea>
                                        <validation :validation="form.errors.message" />
                                    </div>
                                    <div class="mb-4">
                                        <img v-if="captcha_image" :src="captcha_image" alt="Captcha" height="50"> <br> <br>
                                        <button type="button" v-on:click="reloadCaptcha" class="btn btn-primary ml-2">Refresh Captcha</button>
                                        <br>
                                        <input type="hidden" v-model="form.captcha_key">
                                        <input type="text" v-model="form.captcha" class="form-control">
                                        <validation :validation="form.errors.captcha" />
                                    </div>
                                    <div class="mb-4">
                                        <div v-show="is_success" class="alert alert-success mb-3 alert-dismissible fade show" role="alert">
                                            {{success_message}}
                                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        <button type="submit" class="btn btn-primary col-12 mt-3" :disabled="form.processing">
                                            <button-label :processing="form.processing" label="Submit"/>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-12 t-green">
                                    <h3>Head Office & Factory</h3>
                                    <p class="ws-preline">{{profile.value.address_main}}</p>
                                    <div class="py-3"></div>
                                    <h3>Sales Representative Office</h3>
                                    <p class="ws-preline">{{profile.value.address_sales}}</p>                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'
    import Validation from '@/Components/Validation'
    import ButtonLabel from '@/Web/Components/ButtonLabel'

    export default {
        components: {
            Layout,
            PageTitle,
            Validation,
            ButtonLabel
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'contact',
            'asset'
        ],

        data () {
            return {
                captcha_image: '',
                is_success: false,            

                form: this.$inertia.form({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    captcha: '',
                    captcha_key: '',
                }),

                get_in_touch: this.$page.props.lang == 'id' ? 'Hubungi Kami' : 'Get In Touch',
                success_message: this.$page.props.lang == 'id' ? 'Pesan Anda Berhasil Terkirim' : 'Your Message Sent Successfully',
                pageTitle: this.$page.props.lang == 'id' ? this.contact.value.page_title_id : this.contact.value.page_title_en,
                background: this.asset+'images/'+this.contact.value.page_title_background,
            }
        },

        methods: {
            reloadCaptcha() {
                window.axios.get(route('home')+'/captcha/api/mini')
                    .then(res => {
                        this.captcha_image = res.data.img;
                        this.form.captcha_key = res.data.key;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            submit() {
                let url = route('message');
                this.form.post(url,{
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form.clearErrors();
                        this.is_success = true;
                    }
                })
            }
        },

        mounted() {
            this.reloadCaptcha();
        }
    }
</script>