<template>
    <!-- Hero -->
    <div class="bg-hero" v-bind:style="{ 'background-image': 'url(' + background + ')' }">
        <div class="container">
            <div class="row row-cols-1">
                <div class="col-lg-5 bg-bannerhome">
                    <h1 class="hero-title">{{title}}</h1>
                    <p class="hero-description">{{description}}</p>

                    <a :href="route('about')" class="btn btn-primary hero-button">{{read_more}}</a>
                </div>
            </div>
        </div>
        <img class="hero-scroll" :src="asset+'icon/btn-scroll.png'" alt="Scroll">
    </div>
</template>

<script>
    export default {
        props: ['hero', 'asset'],
        data() {
            return {
                title: this.$page.props.lang == 'id' ? this.hero.value.title_id : this.hero.value.title_en,
                description: this.$page.props.lang == 'id' ? this.hero.value.description_id : this.hero.value.description_en,
                read_more: this.$page.props.lang == 'id' ? 'Selengkapnya' : 'Read More',
                background: this.asset+'images/'+this.hero.value.image
            }
        }
    }
</script>