<template>
    <div class="bg-product">
        <div class="container py-5">
            <div class="row row cols-1 pt-5 mb-5">
                <div class="col">
                    <h2 class="home-section-title" v-html="our_product"></h2>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-lg-3 g-4">
                <div class="col">
                    <a :href="route('product')">    
                        <div class="home-product">
                            <div class="content">
                                <img :src="asset+'images/'+home_product.value.product_icon" alt="Produk">
                                <p>{{product}}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col">
                    <a :href="route('carrageenan')">
                        <div class="home-product">
                            <div class="content">
                                <img :src="asset+'images/'+home_product.value.carrageenan_icon" alt="Produk">
                                <p>{{carrageenan}}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col">
                    <a :href="route('product-application')">
                        <div class="home-product">
                            <div class="content">
                                <img :src="asset+'images/'+home_product.value.application_icon" alt="Produk">
                                <p>{{application}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['home_product', 'asset'],
        data() {
            return {
                our_product: this.$page.props.lang == 'id' ? '<strong>Produk</strong> Kami' : '<strong>Our</strong> Product',
                product: this.$page.props.lang == 'id' ? this.home_product.value.product_title_id : this.home_product.value.product_title_en,
                carrageenan: this.$page.props.lang == 'id' ? this.home_product.value.carrageenan_title_id : this.home_product.value.carrageenan_title_en,
                application: this.$page.props.lang == 'id' ? this.home_product.value.application_title_id : this.home_product.value.application_title_en,
            }
        }
    }
</script>