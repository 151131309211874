<template>
    <Layout :menu="menu" :logo="logo" :profile="profile" :socials="socials" :asset="asset">
        
        <div class="bg-product">
            
            <page-title :title="pageTitle" :background="background" />

            <div class="container">
                <div class="row row-cols-1 mb-3">
                    <div class="col mb-3">
                        <form @submit.prevent="submitSearch">
                            <div class="input-group">
                                <input type="text" class="form-control" :placeholder="submit+' ...'" v-model="q_model">
                                <button class="btn btn-outline-secondary" type="submit">{{submit}}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row row-cols-1">
                    <div class="col mb-3">
                        <ul>
                            <li v-for="item in search" :key="item.id">
                                <a :href="item.link">
                                    <h5>{{item.title}}</h5> <br>
                                    <p>{{item.content.substr(0, 80)}}...</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>

    </Layout>
</template>

<script>
    import Layout from '@/Web/Layout/Version1'
    import PageTitle from '@/Web/Components/PageTitle'

    export default {
        components: {
            Layout,
            PageTitle
        },

        props: [
            'logo',
            'socials',
            'profile',
            'menu',
            'blog',
            'search',
            'q',
            'asset'
        ],

        data () {
            return {
                q_model: this.q,
                submit: this.$page.props.lang == 'id' ? 'Cari' : 'Search' ,
                read: this.$page.props.lang == 'id' ? 'Selengkapnya' : 'Read More' ,
                pageTitle: this.$page.props.lang == 'id' ? 'Pencarian' : 'Search',
                background: this.asset+'images/'+this.blog.value.page_title_background,
            }
        },

        methods: {
            submitSearch() {
                console.log('test')
                location.href = route('search', {q: this.q_model});
            }
        }
    }
</script>
